import firebase from "firebase/app";
import "firebase/storage";
import "firebase/auth";
import "firebase/database";
import "firebase/messaging";

let config;
if (
  window.location.host === "tejimandicms.web.app" ||
  window.location.host === "admin.tejimandi.com" ||
  window.location.host === "tejimandicms.firebaseapp.com"
) {
  // const baseURL = 'https://prod-api.manpasand.app/index.php';
  const baseURL = "https://prod-api.tejimandi.com/index.php";
  config = Object.freeze({
    IS_STAGING: false,
    API_URL: `${baseURL}`,
    APP_NAME: "TejiMandi",
    APP_VERSION: process.env.REACT_APP_VERSION,
    GRAPHQL_URL: "https://prod-v2-api.tejimandi.com/graphql",
    WSS_URL: "wss://prod-graphql.tejimandi.com/graphql",
    CLEVERTAP_ACCOUNT_ID: "W48-7R6-W65Z",
    CONTENT_UPLOAD_URL: `${baseURL}?r=v1/upload-content-video`,
    BRANCH_KEY: "key_live_mmMJl0srLs1zuRLhtte5KinmvDgS20T0",
    BRANCH_NAME: "tejimandi:",
    FIREBASE_MESSAGE_KEY:
      "AAAAzfSaV4o:APA91bEV0YSCIjziJCrgmwzgLlPy-jdfxru5KfXmFJSBD2UyzzK4_YL2S0KJB6GYG23cu6QjUrMpu0SNqYPix85S2gFILU1Qi9-VPzrWy2CoYIUPNdlr7jVa_3FZUxatUsWT-JctkCQn",
    FIREBASE_CONFIG: {
      apiKey: "AIzaSyDnztX2ThH6Qx4HOYwlz_vLr46RZJTn604",
      authDomain: "tejimandiprod.firebaseapp.com",
      databaseURL: "https://tejimandiprod.firebaseio.com",
      projectId: "tejimandiprod",
      storageBucket: "tejimandiprod.appspot.com",
      messagingSenderId: "651242643694",
      appId: "1:651242643694:web:ede12c1a3efb10ccfc13ff",
      measurementId: "G-BNP47W6LFC",
    },
  });
} else if (
  window.location.host === "adminstaging.tejimandi.com"
  // || window.location.host === 'admin.tejimandi.com'
  // || window.location.host === 'tejimandicms.firebaseapp.com'
) {
  const baseURL = "https://stage-api.tejimandi.com/index.php";
  // const baseURL = 'https://stage-api.manpasand.app/index.php';
  config = Object.freeze({
    IS_STAGING: false,
    API_URL: `${baseURL}`,
    APP_NAME: "TejiMandi",
    APP_VERSION: process.env.REACT_APP_VERSION,
    GRAPHQL_URL: "https://stage-graphql.tejimandi.com/graphql",
    WSS_URL: "wss://stage-graphql.tejimandi.com/graphql",
    CLEVERTAP_ACCOUNT_ID: "W48-7R6-W65Z",
    CONTENT_UPLOAD_URL: `${baseURL}?r=v1/upload-content-video`,
    BRANCH_KEY: "key_live_mmMJl0srLs1zuRLhtte5KinmvDgS20T0",
    BRANCH_NAME: "tejimandi:",
    FIREBASE_MESSAGE_KEY:
      "AAAAzfSaV4o:APA91bEV0YSCIjziJCrgmwzgLlPy-jdfxru5KfXmFJSBD2UyzzK4_YL2S0KJB6GYG23cu6QjUrMpu0SNqYPix85S2gFILU1Qi9-VPzrWy2CoYIUPNdlr7jVa_3FZUxatUsWT-JctkCQn",
    FIREBASE_CONFIG: {
      apiKey: "AIzaSyDnztX2ThH6Qx4HOYwlz_vLr46RZJTn604",
      authDomain: "http://adminstaging.firebaseapp.com/",
      databaseURL: "https://tejimandiprod.firebaseio.com",
      projectId: "tejimandiprod",
      storageBucket: "tejimandiprod.appspot.com",
      messagingSenderId: "651242643694",
      appId: "1:651242643694:web:ede12c1a3efb10ccfc13ff",
      measurementId: "G-BNP47W6LFC",
    },
  });
} else {
  const baseURL = "https://dev-api.tejimandi.com/index.php";
  // const baseURL = 'https://dev-api.manpasand.app/index.php';
  config = Object.freeze({
    IS_STAGING: true,
    API_URL: `${baseURL}`,
    APP_VERSION: process.env.REACT_APP_VERSION,
    APP_NAME: "TejiMandi - Beta",
    // GRAPHQL_URL: 'http://localhost:4000/graphql',
    GRAPHQL_URL: "https://prod-v2-api.tejimandi.com/graphql",
    WSS_URL: "wss://dev-graphqlws.manpasand.app/graphql",
    BRANCH_KEY: "key_test_gdSTb5qBUwYxCRHgqzwDTljnswmHW5Zb",
    BRANCH_NAME: "tejimandibeta:",
    CLEVERTAP_ACCOUNT_ID: "W48-7R6-W65Z",
    CONTENT_UPLOAD_URL: `${baseURL}?r=v1/upload-content-video`,
    FIREBASE_MESSAGE_KEY:
      "AAAAWC1Y1Ss:APA91bHewpsfkhG_IhEKbAhhEHQg1Csp5r21hegG2NFvkt1eBbjFcy2bO_9DYGi4bzpG9f5_6KcRtfSKe4T7DFNa0WCT2z8yd44bLXvFQBVi5lTWbl0X6CHWEgFYBCerwKadrxgyAcyv",
    FIREBASE_CONFIG: {
      apiKey: "AIzaSyCC1B7ED2OwKu5_P9r5Y1Y7YDUNpZwHsWU",
      authDomain: "tejimandibeta.firebaseapp.com",
      databaseURL: "https://tejimandibeta.firebaseio.com",
      projectId: "tejimandibeta",
      storageBucket: "tejimandibeta.appspot.com",
      messagingSenderId: "250588840230",
      appId: "1:250588840230:web:f55085dec552eeb1c801e7",
      measurementId: "G-C4XW23Z8JR",
    },
  });
  // const baseURL = 'https://stage-api.tejimandi.com/index.php';
  // // const baseURL = 'https://stage-api.manpasand.app/index.php';
  // config = Object.freeze({
  //   IS_STAGING: false,
  //   API_URL: `${baseURL}`,
  //   APP_NAME: 'TejiMandi',
  //   APP_VERSION: process.env.REACT_APP_VERSION,
  //   GRAPHQL_URL: 'https://stage-graphql.tejimandi.com/graphql',
  //   WSS_URL: 'wss://stage-graphql.tejimandi.com/graphql',
  //   CLEVERTAP_ACCOUNT_ID: 'W48-7R6-W65Z',
  //   CONTENT_UPLOAD_URL: `${baseURL}?r=v1/upload-content-video`,
  //   BRANCH_KEY: 'key_live_mmMJl0srLs1zuRLhtte5KinmvDgS20T0',
  //   BRANCH_NAME: 'tejimandi:',
  //   FIREBASE_MESSAGE_KEY:
  //     'AAAAzfSaV4o:APA91bEV0YSCIjziJCrgmwzgLlPy-jdfxru5KfXmFJSBD2UyzzK4_YL2S0KJB6GYG23cu6QjUrMpu0SNqYPix85S2gFILU1Qi9-VPzrWy2CoYIUPNdlr7jVa_3FZUxatUsWT-JctkCQn',
  //   FIREBASE_CONFIG: {
  //     apiKey: 'AIzaSyDnztX2ThH6Qx4HOYwlz_vLr46RZJTn604',
  //     authDomain: 'http://adminstaging.firebaseapp.com/',
  //     databaseURL: 'https://tejimandiprod.firebaseio.com',
  //     projectId: 'tejimandiprod',
  //     storageBucket: 'tejimandiprod.appspot.com',
  //     messagingSenderId: '651242643694',
  //     appId: '1:651242643694:web:ede12c1a3efb10ccfc13ff',
  //     measurementId: 'G-BNP47W6LFC',
  //   },
  // });
}

firebase.initializeApp(config.FIREBASE_CONFIG);

export const googleAuthProvider = new firebase.auth.GoogleAuthProvider();

export const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();

export const firebaseAuth = firebase.auth();

export const database = firebase.database();

export const storage = firebase.storage();

export const DEFAULT_INTERVAL = 15 * 60 * 1000;

export const {
  APP_VERSION,
  IS_STAGING,
  APP_NAME,
  API_URL,
  GRAPHQL_URL,
  WSS_URL,
  FIREBASE_MESSAGE_KEY,
  BRANCH_KEY,
  BRANCH_NAME,
  CLEVERTAP_ACCOUNT_ID,
} = config;

export default firebase;
